import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "TabNav",
  code: "TabNav"
};
export const _frontmatter = {
  "menuLabel": "Tab Nav",
  "sortOrder": 20.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tab Nav`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-tab-nav--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <p>{`The Tab Navigation component allows users to switch between different views or content sections within the same context. It organizes related content into labeled tabs, enabling quick navigation and reducing the need for multiple page loads.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      