import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Overlay",
  "sortOrder": 15.1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Overlay`}</h1>
    <p>{`The Overlay component is a foundational part of the design system, used to present content that requires user focus or interaction without navigating away from the current context. Overlays ensure users remain anchored to their task wile introducing supplementary or critical information.`}</p>
    <h2>{`Released`}</h2>
    <p>{`The Overlay component is available in three variants to accomodate different use caes and content requirements:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "modal"
        }}>{`Modal`}</a>{` is a centered, fixed-width container designed fro consise, focused interactions.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "half-sheet"
        }}>{`Half Sheet`}</a>{` is a container that slides in from the bottom of the screen.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "sheet"
        }}>{`Sheet`}</a>{` is a full-height, sliding container origination from the bottom of the screen.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      