import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "MarketTemplate",
  code: "MarketTemplate"
};
export const imageName = {
  display: "Slider Market",
  code: "slidermarket"
};
export const _frontmatter = {
  "menuLabel": "Slider Market",
  "sortOrder": 20.1
};
const layoutProps = {
  componentName,
  imageName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Slider Market`}</h1>
    <ComponentDescription of={imageName.code} mdxType="ComponentDescription" />
    <ComponentStatusBar of={imageName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={imageName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-markets-markettemplate--slider-market" asEmbed={true} mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      