import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  forwardRef,
  ReactNode,
  Children,
  isValidElement,
  RefObject,
  CSSProperties,
} from "react";
import { CardProps, CardWithStatics } from "./types";
import cx from "components/src/utils/cx";
import { roundedClasses } from "../../shared/styles/roundedStyles";
import { tailwindSemanticBgColors } from "../../shared/styles/colorStyles";
import get from "lodash-es/get";
import { CardHeader } from "./components/CardHeader";
import { CardPreFooter } from "./components/CardPreFooter";
import { CardFooter } from "./components/CardFooter";
import { CardSubHeader } from "./components/CardSubHeader";
import { addProps } from "./js/addProps";
import { gapClasses } from "../Grid/js/gridClasses";
import useForwardRef from "components/src/shared/hooks/useForwardRef";
import { Header } from "./components/Header";
import { randomIdString } from "components/src/shared/utils/randomIdString";


const _Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      id = "",
      rounded = "sm",
      bg = "bg-default",
      noPad,
      showBorder = true,
      className = "",
      title,
      description,
      actionText,
      children,
      collapsible,
      expandable,
      restrictTo,
      gap = "none",
      flow = "column",
      initiallyOpen = true,
      defaultHeader,
      headerBg,
      headerDetails = [],
      animation = true,
      animationSpeed = 200,
      initialHeight = "auto",
      onToggle,
      onCardClick,
      ...rest
    }: CardProps,
    ref
  ) => {
    const defaultIdStr = useRef(randomIdString());
    const itemRef = useForwardRef<HTMLDivElement | null>(
      ref as RefObject<HTMLDivElement>
    );
    const _id = id ? id : `cui__card-${defaultIdStr.current}`;
    const _bodyId = `cui__card-body-${id ? id : defaultIdStr.current}`;

    const [isOpen, setOpen] = useState(initiallyOpen);
    const [isExpanded, setIsExpanded] = useState(initiallyOpen && initialHeight !== "auto");

    const cardState = useMemo(()=>{
      return isOpen ? "open" : isExpanded ? "expanded" : "closed"
    },[isOpen, isExpanded]);

    const usesDefaultHeader = !!title || !!description || !!actionText || !!headerDetails.length;
 
    let footer: ReactNode,
      header: ReactNode,
      preFooter: ReactNode,
      subHeader: ReactNode;
    let body: ReactNode[] = [];

    const setOpenState = (v: any) => {
      onToggle && onToggle({ isOpen: !v, id});
      return !v
    };

    const handleKeyDown = useCallback((e)=>{
      const {key, code, keyCode, target, currentTarget } = e;
      const wasSpaceKey = key === " " || code === "Space" || keyCode === 32;
      if (target === currentTarget && (key === "Enter" || wasSpaceKey) ) {
        e.preventDefault();
        collapsible && setOpen(setOpenState(isOpen));;
      }
    },[isOpen]);
    
    const onClick = useCallback(
      () => {
          collapsible && setOpen(setOpenState(isOpen));;
      },
      [collapsible, isOpen]
    );

    Children.forEach(children, (child) => {
      if (!child || (!isValidElement(child) && typeof child !== "string"))
        return;
      let _name: string | null;
      if (typeof child === "string") {
        body.push(child);
      } else if (typeof child === "object") {
        // @ts-ignore
        _name =
          get(child.props, "mdxType", null) ||
          get(child.type, "displayName", null)?.replace(".", "") ||
          get(child.type, "name", null);
        // @ts-ignore
        switch (_name) {
          case "CardFooter":
            footer = { ...child };
            footer = addProps(footer, { noPad, isOpen });
            break;
          case "CardPreFooter":
            preFooter = { ...child };
            preFooter = addProps(preFooter, { noPad });
            break;
          case "CardHeader":
            header = { ...child };
            const _props: any = { onClick, isOpen };
            if (collapsible !== undefined) _props.collapsible = collapsible;
            if (headerDetails.length) _props.details = headerDetails;
            if (headerBg) _props.bg = headerBg;
            header = addProps(header, _props);
            break;
          case "CardSubHeader":
            subHeader = { ...child };
            subHeader = addProps(subHeader, { noPad });
            break;
          default:
            // @ts-ignore
            if (
              _name &&
              restrictTo &&
              Array.isArray(restrictTo) &&
              restrictTo.length &&
              restrictTo.includes(_name)
            ) {
              body.push(child);
            } else if (!restrictTo) {
              body.push(child);
            }
        }
      }
    });

    const headProps = useMemo(() => ({
      title,
      description,
      actionText,
      isOpen,
      onClick,
      collapsible,
      details: headerDetails,
      bg: headerBg
    }), [title, description, actionText, isOpen, onClick, collapsible, headerDetails, headerBg]);

    const _styles = useMemo(() => {
      let cardHeight: Record<PropertyKey, CSSProperties["height"]> = {
        height: 0
      };
      if (isExpanded) cardHeight = {
        height: initialHeight
      };
      if (isOpen) cardHeight = {
        height: "calc-size(auto,size)"
      }
      return {
        ...cardHeight,
        transitionDuration: `${animationSpeed}`
      }
    },[initialHeight, isOpen, isExpanded, animationSpeed])

    return (
      <div
        className={cx(
          {
            [roundedClasses[rounded]]: true,
            [tailwindSemanticBgColors[bg]]: true,
            "cui-py-sm": !noPad && !footer && !header && !usesDefaultHeader,
            "cui-pt-sm": !noPad && !header && !usesDefaultHeader,
            "cui-pb-sm": !noPad && !footer && isOpen,
            "cui-border-1 cui-border-solid cui-border-fg-subtle": showBorder,
            [gapClasses[gap]]: isOpen,
            [`${className || ""}`]: true
          },
          `cui__card-wrapper cui-relative cui-overflow-hidden cui-box-border cui-grid focus-visible:cui-ring-2 focus-visible:cui-ring-fg-focus focus-visible:cui-ring-offset-2 focus-visible:cui-outline-none`
        )}
        tabIndex={0}
        data-cy="cui__card"
        ref={itemRef}
        id={_id}
        onClick={onCardClick}
        onKeyDown={handleKeyDown}
        aria-controls={_bodyId}
        aria-expanded={isOpen ? "true" : "false"}
        role="button"
        {...rest}
      >
          <Header
            usesDefaultHeader={usesDefaultHeader}
            header={header}
            defaultHeader={defaultHeader}
            headProps={headProps}
          />
          <div className={cx({
            "cui-transition-all": animation
            }, "cui__card-contents cui-w-auto cui-h-auto cui-overflow-hidden cui-grid")} id={_bodyId} aria-labelledby={_id}
            style={_styles}
            role="region"
          >
            {subHeader ? subHeader : null}
            {body ? (
              <div
                className={cx({
                  "cui-px-md": !noPad,
                  "cui-flex cui-flex-col": flow === "column",
                  "cui-flex cui-flex-wrap": flow === "row",
                  [gapClasses[gap]]: true,
                }, "cui__card-body")}
              >
                {body}
              </div>
            ) : null}
            {preFooter ? preFooter : null}
            {footer ? footer : null}
          </div>
      </div>
    );
  }
) as CardWithStatics;

export const Card = Object.assign({}, _Card, {
  Header: CardHeader,
  SubHeader: CardSubHeader,
  PreFooter: CardPreFooter,
  Footer: CardFooter,
  displayName: "Card",
});