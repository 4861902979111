import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Toggle",
  code: "Toggle"
};
export const _frontmatter = {
  "menuLabel": "Toggle",
  "sortOrder": 20.4
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Toggle`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-toggle--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Toggle } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`Ensure the toggle switch is used for binary options, allowing users to enable or disable a single setting. Use clear and concise labels to indicate the function of each toggle switch, enhancing user understanding and accessibility.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The toggle passes color contrast requirements and includes an icon when switched on.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        It functions seamlessly with keyboard navigation.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        The toggle's label or icon clearly communicates its purpose or the action that it will trigger. Users also receive appropriate feedback after clicking the toggle, such as a success message or a change in page content.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        The toggle component is accessible to users with a range of abilities and devices, and adheres to web standards and best practices. The component is also tested thoroughly to ensure that it functions correctly in a variety of contexts and situations.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      