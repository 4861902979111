import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "sortOrder": 2.2,
  "menuLabel": "Quick Start (iOS)"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Quick Start (iOS)`}</h1>
    <p className="p-large">The following info will help you get Caesars UI setup in your project.</p>
    <h2>{`Swift Package `}<inlineCode parentName="h2">{`caesars-ui-ios`}</inlineCode></h2>
    <p>{`The package exports the following products`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CUI`}</inlineCode>{` - `}<a parentName="li" {...{
          "href": "/tokens"
        }}>{`Semantic tokens`}</a>{` and `}<a parentName="li" {...{
          "href": "/foundations/"
        }}>{`core tokens`}</a>{` (e.g. themes, fonts, colors, image assets, etc.)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CUI Components`}</inlineCode>{` - Reusable design system `}<a parentName="li" {...{
          "href": "/components/"
        }}>{`components`}</a></li>
    </ul>
    <h2>{`Installation`}</h2>
    <h4>{`Xcode Project`}</h4>
    <p>{`You can add `}<inlineCode parentName="p">{`caesars-ui-ios`}</inlineCode>{` to an Xcode project as a package dependency.`}</p>
    <ol>
      <li parentName="ol">{`From the `}<strong parentName="li">{`File`}</strong>{` menu, select `}<strong parentName="li">{`Add Package Dependencies...`}</strong></li>
      <li parentName="ol">{`Enter `}<inlineCode parentName="li">{`https://gitlab.com/caesarsdigital/usa/services/sportsbook/caesars-ui-ds/caesars-ui-ios`}</inlineCode>{` into the package repository URL text field.`}</li>
      <li parentName="ol">{`Add the package to the desired project and select the required package products.`}</li>
    </ol>
    <h4>{`Swift Package`}</h4>
    <p>{`You can add `}<inlineCode parentName="p">{`caesars-ui-ios`}</inlineCode>{` to another Swift Packate as a dependency in your `}<inlineCode parentName="p">{`Package.swift`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`    dependencies: [
        .package(url: "https://gitlab.com/caesarsdigital/usa/services/sportsbook/caesars-ui-ds/caesars-ui-ios", exact: "1.22.0")
    ],
    targets: [
        .target(
            name: "MyLibrary",
            dependencies: [
                .product(name: "CUI", package: "caesars-ui-ios"),
                .product(name: "CUI Components", package: "caesars-ui-ios")
            ]
        )
    ]
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Release versions can be found in `}<a parentName="p" {...{
          "href": "/whats-new"
        }}>{`What's New`}</a>{` or in the `}<a parentName="p" {...{
          "href": "https://gitlab.com/caesarsdigital/usa/services/sportsbook/caesars-ui-ds/caesars-ui-ios/-/tags"
        }}>{`repository`}</a>{`.`}</p>
    </blockquote>
    <h2>{`Using Tokens`}</h2>
    <p>{`In order to use our tokens, you'll have to import the `}<inlineCode parentName="p">{`CUI`}</inlineCode>{` package product.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import CUI
`}</code></pre>
    <p>{`You will then have access to the core tokens as well as the semantic tokens.`}</p>
    <h4>{`SwiftUI`}</h4>
    <p>{`Semantic tokens are associated with one of CUI supported Themes. CUI uses SwiftUI's `}<inlineCode parentName="p">{`EnvironmentValues`}</inlineCode>{` to provide default support for semantic tokens. You can inject a CUI Theme token into an App or specific View hierarchy like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import CUI
import SwiftUI

@main
struct MyApp: App {
    var body: some Scene {
        WindowGroup {
            ContentView()
                .cuiTheme(.czr) // Sets the CZR Theme for the app (This is equivalent to \`.environment(\\.cuiTheme, .czr)\`)
        }
    }
}
`}</code></pre>
    <p>{`Tokens can be used within many standard View modifiers using the syntax `}<inlineCode parentName="p">{`.cuiCore(<coreToken>)`}</inlineCode>{` or `}<inlineCode parentName="p">{`.cuiTheme(<semanticToken>)`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var body: some View {
    Text("CUI Tokens")
        .font(.cuiTheme(.displayLgBold)) // Semantic font token
        .foregroundColor(.cuiTheme(.highlightAccentMinimal)) // Semantic color token for the Text
        .padding(.vertical, .cuiCore(.spacing2px)) // Core size token
        .padding(.horizontal, .cuiTheme(.xxs)) // Semantic size token
        .background(.cuiTheme(.highlightAccent)) // Semantic color token for the Text background
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`NOTE: CUI uses SwiftUI's EnvironmentValues to access the current theme in the view hierarchy.`}</p>
    </blockquote>
    <p>{`However, not all View modifiers are able to resolve the `}<inlineCode parentName="p">{`Environment`}</inlineCode>{` theme internally. Sometimes it will be necessary to manually access the current theme from the Environment like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`    @Environment(\\.cuiTheme) private var theme // Access the current theme from the Environment

    // Some Shape view code with a semantic theme stroke color
        .stroke(theme.color(.highlightAccentSubtle), lineWidth: .borderWidth1)
`}</code></pre>
    <h4>{`UIKit`}</h4>
    <p>{`Accessing tokens in UIKit is also similar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let label = UILabel()
label.text = "Hello, CUI!"
label.font = theme.font(.displayLgBold) // Semantic font token
label.textColor = theme.color(.highlightAccentMinimal) // Semantic color token
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`NOTE: In UIKit there is no equivalent to SwiftUI's Environment. Managing the current CUI Theme must be handled manually.`}</p>
    </blockquote>
    <h2>{`Using Components`}</h2>
    <p>{`In order to use our components, you'll have to import the `}<inlineCode parentName="p">{`CUIComponents`}</inlineCode>{` package product.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import CUIComponents
`}</code></pre>
    <p>{`Basic usage looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`var body: some View {
    ButtonView(config: .init(text: "Hello, CUI!", size: .md) { event in
        // Trigger some action when the button is tapped
        print("Button tapped")

        // Access to the configuration object to change the state of the component based on the triggered action, e.g.
        event.button.selected.toggle()
    })
}
`}</code></pre>
    <h2>{`Sandbox App`}</h2>
    <p>{`The CUI repository includes a sandbox application that shows the available tokens and assets in the library as well as component demos. The code for this sample project also gives many examples of how components can be integrated into your project.`}</p>
    <h4>{`Setup`}</h4>
    <ul>
      <li parentName="ul">{`Clone the `}<a parentName="li" {...{
          "href": "https://gitlab.com/caesarsdigital/usa/services/sportsbook/caesars-ui-ds/caesars-ui-ios.git"
        }}>{`CUI repository`}</a></li>
      <li parentName="ul">{`Open the `}<inlineCode parentName="li">{`Sandbox Projects/swiftui-sandbox/swiftui-sandbox.xcodeproj`}</inlineCode></li>
      <li parentName="ul">{`You can now run the sandbox application on a simulator or physical device`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      