import { useState, useEffect } from "react";

export const useStorybookMdx = ({code, parent}) => {
    const [data, setData] = useState({});
  
    useEffect(()=>{
      const fetchData = async () => { 
        if (!Object.keys(data).length) {
          const path = code;
          const parentPath = parent ? `${parent}/` : "";
            try {
              // This needs to be changed: use 'parent' AND 'path' if both exist
              const data = await import(`../../../../packages/components/src/components/${parentPath}${path}/${path}.stories.mdx`);
              setData(data);
            } catch (e) {
              console.log(e);
            }

        }
        // console.log(data, component);
      }
      fetchData();
    },[]);

    return data;
}
export default useStorybookMdx;
