import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "MarketTemplate",
  code: "MarketTemplate"
};
export const _frontmatter = {
  "menuLabel": "Market Template",
  "sortOrder": 1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Market Templates`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "slider-market"
        }}>{`Slider Market`}</a></li>
    </ul>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-text-field--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { MarketFooter } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`A text field component is a versatile and essential UI element that enables users to input and edit text. It supports various states to ensure a seamless and intuitive user experience. In its default state, the text field is ready for user interaction, but it can also display valid and invalid states based on the input's conformity to the set validation rules. A validation function can be used to dynamically check the input against specific criteria, providing immediate feedback to the user. `}</p>
    <p>{`For required fields, the component can be configured to ensure that users cannot submit a form without completing the necessary information.`}</p>
    <p>{`The text field component also offers several functional states to cater to different scenarios. The disabled state prevents user interaction, effectively making the field read-only and often used for inputs that should not be changed once set. The loading state is particularly useful during asynchronous operations, such as data fetching or submission, giving users a visual cue that processing is underway. `}</p>
    <p>{`An inset label variant enhances the design by placing the label inside the text field, saving space. `}</p>
    <p>{`Additionally, the component can include icons within the text field for added functionality, such as a search icon for search fields or a calendar icon for date inputs. The currency variant formats the input to handle monetary values, ensuring proper display and user input.`}</p>
    <p>{`Custom colors allow for further customization, enabling the Text Field to align with the application's branding and design guidelines.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The TextField component has a clear visual indication that it is a TextField, such as a shape or label that distinguishes it from other page elements. It has sufficient contrast with the background to be easily visible, and any hover or focus states are also clearly distinguishable.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        Users can interact with the TextField using a variety of input methods, including keyboard and mouse. The TextField also has a consistent behavior across the site, so that users can anticipate what will happen when they click on it.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        The TextField’s label or icon clearly communicates its purpose or the action that it will trigger. Users also receive appropriate feedback after clicking the TextField, such as a success message or a change in page content.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        The TextField component is accessible to users with a range of abilities and devices, and adheres to web standards and best practices. The component is also tested thoroughly to ensure that it functions correctly in a variety of contexts and situations.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      