import React, { ReactNode, isValidElement } from "react";
import { CardHeadProps } from "../types";
import { CardHead } from "./CardHead";
import { addProps } from "../js/addProps";

export const Header = ({
  defaultHeader, header = null, usesDefaultHeader, headProps,
}: {
  defaultHeader: any;
  usesDefaultHeader: boolean;
  header: ReactNode | string;
  headProps: CardHeadProps;
}) => {
  if (usesDefaultHeader) {
    if (defaultHeader) {
      return defaultHeader;
    } else {
      return <CardHead {...headProps} />;
    }
  }
  if (header && !usesDefaultHeader && isValidElement(header)) {
    const _header = addProps(header, { isOpen: headProps?.isOpen });
    return <div>{_header}</div>;
  } else {
    return null;
  }
};
