import React, { useRef, useEffect } from "react";
import cx from "components/src/utils/cx";
import Arrow from "./Arrow";
// import anime, { AnimeInstance } from "animejs";
import { MarketButtonArrowAnimationOptions, MarketButtonDataQaType } from "../types";

const ArrowIndicators = ({
  selected,
  oddsState,
  id,
  isViewable = true,
  animation,
  dataQa
}: {
  selected: boolean;
  isViewable: boolean;
  oddsState: {
    UP: boolean;
    DOWN: boolean;
    EVEN: boolean;
  };
  id: string;
  animation: MarketButtonArrowAnimationOptions;
  dataQa: MarketButtonDataQaType;
}) => {
  // const animeRef = useRef<AnimeInstance | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   animeRef.current = anime({
  //     targets: [containerRef.current],
  //     opacity: [0, 1],
  //     duration: 500,
  //     direction: "alternate",
  //     loop: animation === "reduced" ? 4 : 6,
  //     easing: "linear",
  //     autoplay: false,
  //   });

  //   if (oddsState.EVEN || !isViewable || animation === "off") {
  //     animeRef.current?.pause();
  //   } else {
  //     animeRef.current?.restart();
  //   }
  //   return () => {
  //     animeRef.current = null;
  //   }
  // });

  return (
    <div
      ref={containerRef}
      className={cx(
        {
          "cui-ring-status-positive": oddsState.UP && !selected,
          "cui-ring-status-negative": oddsState.DOWN && !selected,
          "cui-ring-neutrals-transparent": oddsState.EVEN,
          "cui-ring-fg-inverse": (oddsState.UP || oddsState.DOWN) && selected,
        },
        "cui-rounded-[2px] cui-absolute cui-inset-0 cui-ring-1 cui-ring-solid cui-z-10"
      )}
      data-cy="market-button-arrow-indicators"
      data-qa={dataQa?.arrowIndicators}
    >
      <Arrow
        status="positive"
        movement={oddsState.UP}
        id={`positive-${id}`}
        selected={selected}
      />
      <Arrow
        status="negative"
        movement={oddsState.DOWN}
        id={`negative-${id}`}
        selected={selected}
      />
    </div>
  );
};

export default ArrowIndicators;
