import clamp from "lodash-es/clamp";
import { calcValue } from "./calcValue";

export function calcOpenPoint(heights, topPadding, bottomOffset, openValue, closedValue) {
  if (openValue && !closedValue) {
    return clamp(calcValue(openValue, heights),
      0 + topPadding + bottomOffset,
      heights.windowHeight - heights.headerHeight
    )
  }
  if (openValue && closedValue) {
    const calculatedOpenValue = calcValue(openValue, heights);
    const calculatedCloseValue = heights.windowHeight - (closedValue ? calcValue(closedValue, heights) : (heights.headerHeight || 44));
    const _value = calculatedOpenValue > calculatedCloseValue ? calculatedCloseValue - 100 : calculatedOpenValue;
    return clamp(_value,
      0 + topPadding + bottomOffset,
      heights.windowHeight - heights.headerHeight
    )
  }
  return clamp(
    heights.windowHeight - 
      (heights.childrenHeight + heights.headerHeight + heights.footerHeight),
    0 + topPadding + bottomOffset,
    heights.windowHeight - bottomOffset
  );
}
