import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "PillNav",
  code: "PillNav"
};
export const _frontmatter = {
  "menuLabel": "Pill Nav",
  "sortOrder": 16.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Pill Nav`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { PillNav } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The pill nav component simplifies content navigation with intuitive tabbed controls, designed to enhance user experience by organizing content into easily accessible sections. This component employs a pill-shaped design for the navigation tabs, which can be styled to align with the overall aesthetic of your application. Each tab can represent different categories, sections, or steps in a process, making it an excellent choice for multi-step forms, content-heavy pages, or segmented information displays.`}</p>
    <p>{`The pill nav component supports various states including active, inactive, hover, and disabled, providing clear visual feedback to users about their current location and available options. Icons can also be added to the tabs for additional visual cues, enhancing usability. This design ensures that users can quickly and efficiently navigate through content without feeling overwhelmed, resulting in a more stremlined and engaging user experience.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      