import React, { useMemo, useRef, useEffect, useState } from "react";

import { prepareFilters } from "./js/prepareFilters";
import { prepareMarkets } from "./js/prepareMarkets/prepareMarkets";
import { PillNavItemObjType } from "../PillNav/types";
import { MarketTemplateProps, MarketTemplateRecordType, MarketTemplateUpdateMarketOptionsType } from "./types";
import { randomIdString } from "components/src/shared/utils/randomIdString";

// Templates
import { SixPack } from "./templates/SixPack";
import { NColGrouped } from "./templates/NColGrouped";
import { SliderMarket } from "./templates/SliderMarket";
import { Squares } from "./templates/Squares";

// Slider / MarketTabs / SelectionTabs / AlternativeSixPack / combined / stacked / squares

const TEMPLATES: MarketTemplateRecordType = {
  "sixpack": {
    name: "sixpack",
    useAvatar: false,
    template: SixPack
  },
  "standard": {
    name: "standard",
    useAvatar: false,
    template: NColGrouped
  },
  "markettabs": {
    name: "markettabs",
    useAvatar: false,
    template: NColGrouped
  },
  "selectiontabs": {
    name: "selectiontabs",
    useAvatar: false,
    template: NColGrouped
  },
  "combined": {
    name: "combined",
    useAvatar: true,
    template: NColGrouped
  },
  "stacked": {
    name: "stacked",
    useAvatar: true,
    template: NColGrouped
  },
  "slider": {
    name: "slider",
    useAvatar: false,
    template: SliderMarket
  },
  "squares": {
    name: "squares",
    useAvatar: false,
    template: Squares
  }
}

export const MarketTemplate = (props: MarketTemplateProps) => {
  const defaultId = useRef(`cui__market-template-${randomIdString()}`);
  const {
    id = defaultId.current,
    markets = [],
    type = "standard",
    filters = [],
    sport,
    eventDetails,
    skeleton = false,
    animation = true
  } = props;

  const _filters = useMemo(() => prepareFilters(filters, sport, type), [filters]) as PillNavItemObjType[];
  const _eventDetails = {...eventDetails, sport};

  const [_markets, _updateMarket, _updateMarkets] = prepareMarkets({ markets, eventDetails: _eventDetails, type, useAvatar: TEMPLATES[type]?.useAvatar, animation });
  
  if (type in TEMPLATES) {
    return TEMPLATES[type].template({...props, id, markets: _markets, filters: _filters, updateMarket: _updateMarket, eventDetails: _eventDetails});
  }
  return null
};
