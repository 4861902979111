import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Overlay",
  code: "Overlay"
};
export const imageName = {
  display: "Modal",
  code: "Modal"
};
export const _frontmatter = {
  "menuLabel": "Modal",
  "sortOrder": 2
};
const layoutProps = {
  componentName,
  imageName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Modal`}</h1>
    <ComponentDescription of={imageName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={imageName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={imageName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-modal--default-story" asEmbed={true} mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <p>{`The Modal variant of the Overlay component is a centered, focused container used for brief, high-priority interactions. Modals are ideal for tasks that require user attention and action without navigating away from the current page.`}</p>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      