import { useRef, useState, useEffect, Dispatch } from "react";
import { SwiperProps } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Keyboard,
  Mousewheel,
} from "swiper/modules";

import debounce from "lodash-es/debounce";

const swiperModules = [
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Keyboard,
  Mousewheel,
];
export const breakpointValues = {
  mobile: "280",
  tablet: "768",
};

function setEdges(
  isBeginning: boolean,
  setBeginning: Dispatch<boolean>,
  isEnd: boolean,
  setEnd: Dispatch<boolean>
): void {
  isEnd ? setEnd(true) : setEnd(false);
  isBeginning ? setBeginning(true) : setBeginning(false);
}


export const useConfig = ({ navBtns, paginationClass, showPagination, forcePagination, itemSpacing, centerSlides = true, randStr, cssMode, onItemChange, handleItemImpression }: any) => {
  const swiperInstance = useRef<SwiperType | null>(null);

  const [breakpoint, setBreakpoint] = useState();
  const [isEnd, setEnd] = useState<boolean>(false);
  const [isBeginning, setBeginning] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<null | number>(null);

  const onResize = debounce((swiper) => {
    const { isBeginning, isEnd, isLocked } = swiper;
    setEdges(isBeginning, setBeginning, isEnd, setEnd);
    setIsLocked(isLocked);
  }, 200);

  const swiperSpeed = 200;

  function handleVisibleImpressions(visibleItems: number[]) {
    visibleItems.forEach(item => handleItemImpression(item, visibleItems));
  }

  let _cssMode: boolean = cssMode;
  if (cssMode === "false") _cssMode = false;
  if (cssMode === "true") _cssMode = true;

  const [config, setConfig] = useState<SwiperProps>({
    modules: swiperModules,
    a11y: {
      prevSlideMessage: "Previous item",
      nextSlideMessage: "Next item"
    },
    navigation: {
      nextEl: `#${navBtns.nextElId}`,
      prevEl: `#${navBtns.prevElId}`,
    },
    pagination: showPagination || forcePagination
      ? {
          dynamicBullets: true,
          clickable: true,
          el: `.${paginationClass}`,
        }
      : { enabled: false },
    slidesPerView: "auto",
    spaceBetween: itemSpacing,
    // freeMode: { enabled: true, sticky: true, momentumBounce: false, minimumVelocity: .3 },
    keyboard: { enabled: false, onlyInViewport: true },
    mousewheel: { eventsTarget: `.cui__carousel.swiper-slide.cui__carousel_${randStr.current}` },
    speed: swiperSpeed,
    focusableElements: "input, select, option, textarea, video, label",
    resizeObserver: true,
    watchSlidesProgress: true,
    cssMode: _cssMode,
    onSwiper(swiper: SwiperType) {
      const { isBeginning, isEnd, isLocked } = swiper;
      setEdges(isBeginning, setBeginning, isEnd, setEnd);
      setIsLocked(isLocked);
      swiperInstance.current = swiper;
    },
    onBreakpoint(swiper: SwiperType) {
      const { currentBreakpoint } = swiper;
      setBreakpoint(currentBreakpoint);
    },
    onSlideChange({ isEnd, isBeginning, activeIndex, visibleSlidesIndexes = [], realIndex, ...swiper }: SwiperType & { visibleSlidesIndexes?: number[]}) {
      if (typeof onItemChange === "function") {
        onItemChange(activeIndex);
      }
      if (typeof handleItemImpression === "function") {
        if (visibleSlidesIndexes.length) handleVisibleImpressions(visibleSlidesIndexes);
      }
    },
    onReachEnd() {
      setEnd(true);
    },
    onReachBeginning() {
      setBeginning(true);
    },
    onFromEdge({ isEnd, isBeginning}) {
      setEdges(isBeginning, setBeginning, isEnd, setEnd);
    },
    onResize,
    onAfterInit(swiper) {
      setIsLocked(swiper?.isLocked);
      const tid = setTimeout(() => {
        if (typeof swiper !== "undefined") {
          swiper?.update();
          swiper?.slideTo(0, swiperSpeed);
          // @ts-ignore
          const { isBeginning, isEnd, visibleSlidesIndexes, isLocked } = swiper;
          setEdges(isBeginning, setBeginning, isEnd, setEnd);
          setIsLocked(isLocked);
          if (typeof handleItemImpression === "function") {
            if (visibleSlidesIndexes?.length) handleVisibleImpressions(visibleSlidesIndexes);
          }
        }
      }, 500);
      setTimeoutId(tid);
    },
  } as SwiperProps);

  useEffect(()=>{
    return () => {
      if (window && timeoutId) {
        window.clearTimeout(timeoutId);
        onResize.cancel();
      }
    }
  }, [timeoutId]);

  useEffect(() => {
    const breakpoints = {
      [breakpointValues.mobile]: {
        centeredSlides: centerSlides,
        centeredSlidesBounds: centerSlides,
        freeMode: { enabled: false, sticky: true, momentumBounce: false },
      },
      [breakpointValues.tablet]: {
        centeredSlides: false,
        centeredSlidesBounds: false,
        grabCursor: true,
        freeMode: { enabled: true, sticky: false, momentumBounce: false },
      },
    };

    setConfig({
      modules: swiperModules,
      a11y: {
        prevSlideMessage: "Previous item",
        nextSlideMessage: "Next item"
      },
      breakpoints,
      navigation: {
        nextEl: `#${navBtns.nextElId}`,
        prevEl: `#${navBtns.prevElId}`,
      },
      pagination: showPagination || forcePagination
        ? {
            dynamicBullets: true,
            clickable: true,
            el: `.${paginationClass}`,
          }
        : { enabled: false },
      slidesPerView: "auto",
      spaceBetween: itemSpacing,
      // freeMode: { enabled: true, sticky: true, momentumBounce: false, minimumVelocity: .3 },
      keyboard: { enabled: false, onlyInViewport: true },
      mousewheel: { eventsTarget: `.cui__carousel.swiper-slide.cui__carousel_${randStr.current}` },
      speed: swiperSpeed,
      focusableElements: "input, select, option, textarea, video, label",
      resizeObserver: true,
      watchSlidesProgress: true,
      cssMode: _cssMode,
      onSwiper(swiper: SwiperType) {
        const { isBeginning, isEnd, isLocked } = swiper;
        setEdges(isBeginning, setBeginning, isEnd, setEnd);
        setIsLocked(isLocked);
        swiperInstance.current = swiper;
      },
      onBreakpoint(swiper: SwiperType) {
        const { currentBreakpoint } = swiper;
        setBreakpoint(currentBreakpoint);
      },
      onSlideChange({ isEnd, isBeginning, activeIndex, visibleSlidesIndexes = [], realIndex, ...swiper }: SwiperType & { visibleSlidesIndexes?: number[]}) {
        if (typeof onItemChange === "function") {
          onItemChange(activeIndex);
        }
        if (typeof handleItemImpression === "function") {
          if (visibleSlidesIndexes.length) handleVisibleImpressions(visibleSlidesIndexes);
        }
      },
      onReachEnd() {
        setEnd(true);
      },
      onReachBeginning() {
        setBeginning(true);
      },
      onFromEdge({ isEnd, isBeginning}) {
        setEdges(isBeginning, setBeginning, isEnd, setEnd);
      },
      onResize,
      onAfterInit(swiper) {
        setIsLocked(swiper?.isLocked);
        const tid = setTimeout(() => {
          if (typeof swiper !== "undefined") {
            swiper?.update();
            swiper?.slideTo(0, swiperSpeed);
            // @ts-ignore
            const { isBeginning, isEnd, visibleSlidesIndexes, isLocked } = swiper;
            setEdges(isBeginning, setBeginning, isEnd, setEnd);
            setIsLocked(isLocked);
            if (typeof handleItemImpression === "function") {
              if (visibleSlidesIndexes?.length) handleVisibleImpressions(visibleSlidesIndexes);
            }
          }
        }, 500);
        setTimeoutId(tid);
      },
    } as SwiperProps);
  },[centerSlides, handleItemImpression, onItemChange, showPagination, forcePagination, paginationClass, itemSpacing, navBtns, cssMode])

  return { config, swiperInstance, isEnd, isBeginning, breakpoint, isLocked };
};
