import React, { ForwardedRef, forwardRef } from "react";
import cx from "components/src/utils/cx";
import { type MarketButtonDataQaType } from "../types";

export const MarketButtonWrapper = forwardRef(({
    children,
    variant,
    isEnabled,
    className,
    hasDescription,
    fluid = false,
    fullHeight = false,
    dataQa
  }: {
    children: React.ReactNode;
    variant: string;
    isEnabled: boolean;
    className?: string | undefined;
    hasDescription: boolean;
    fluid?: boolean;
    fullHeight?: boolean;
    dataQa?: MarketButtonDataQaType;
  }, wrapperRef: ForwardedRef<HTMLDivElement>) => {
    const [isLarge, isFixed] = [variant === "large", variant === "fixed" || variant === "highlight"];
    return (
      <div
        className={cx(
          {
            "cui-w-[154px]": isLarge && !fluid,
            "cui-h-auto cui-min-h-[142px]": isLarge && !fullHeight,
            "cui-h-[50px]": !isLarge && !fullHeight,
            "cui-h-full": fullHeight,
            "cui-w-[60px] sbk-tablet:cui-w-[114px] sbk-desktop:cui-w-full sbk-desktop:cui-max-w-[264px]":
              isFixed && !fluid && !hasDescription,
            "cui-w-full": variant === "fluid" || fluid || hasDescription,
            "cui-pointer-events-none": !isEnabled,
            [className ? className : '']: true
          },
          "cui__market-button-wrapper [&_*]:cui-box-border cui-relative cui-rounded-[2px] cui-group"
        )}
        data-qa={dataQa?.marketButtonWrapper}
        ref={wrapperRef}
      >
        {children}
      </div>
    );
  });

  export default MarketButtonWrapper
  