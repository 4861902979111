import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "TeamColor",
  code: "TeamColor"
};
export const _frontmatter = {
  "menuLabel": "TeamColor",
  "sortOrder": 20.2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`TeamColor`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-team-color--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { TeamColor } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The Team Color utility component provides a versatile way to enhance elements with the vibrant and distinct colors of sports teams. It enables you to blend two primary colors from two different teams together, creating a unique gradient effect that can be customized further.`}</p>
    <p>{`You can also apply a dark or light screen over the colors to achieve greater contrast and customization, ensuring that the design aligns perfectly with your application's visual identity. The component supports various gradient directions, including horizontal, diagonal, and vertical, giving you the flexibility to create dynamic and visually appealing designs.`}</p>
    <p>{`This utility is ideal for use in sports-related applications, allowing for a personalized and engaging user experience that reflects team branding.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The TeamColor component has a clear visual indication that it is a TeamColor, such as a shape or label that distinguishes it from other page elements. It has sufficient contrast with the background to be easily visible, and any hover or focus states are also clearly distinguishable.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        Users can interact with the TeamColor using a variety of input methods, including keyboard and mouse. The TeamColor also has a consistent behavior across the site, so that users can anticipate what will happen when they click on it.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        The TeamColor’s label or icon clearly communicates its purpose or the action that it will trigger. Users also receive appropriate feedback after clicking the TeamColor, such as a success message or a change in page content.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        The TeamColor component is accessible to users with a range of abilities and devices, and adheres to web standards and best practices. The component is also tested thoroughly to ensure that it functions correctly in a variety of contexts and situations.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      