import React from "react";
import { Card } from "./Card";
import toJsxString from "react-element-to-jsx-string";

export const componentProps = {
    type: {
        control: "text",
        description: "The type of this component.",
        propType: ["String"],
        default: ""
    },
    rounded: {
        control: "select",
        options: ["sm", "md", "lg"],
        description: "Accepts `sm` `md` or `lg`"
    }
};

export const defaultItems = {
    title: "Default Header",
    className: "body-lg-bold"
};

export const Template = (props) => (
    <Card {...props}>
      <Card.SubHeader className="cui-bg-silver-300 cui-text-fg-moderate">This is the SubHeader</Card.SubHeader>
      <div className="cui-h-20 cui-flex cui-items-center">This is the body</div>
      <Card.PreFooter className="cui-bg-silver-300 cui-text-fg-moderate">This is the PreFooter</Card.PreFooter>
      <Card.Footer className="cui-bg-silver-400 cui-text-fg-moderate">This is the Footer</Card.Footer>
    </Card>
);

export const _exampleChildren = `<Card.SubHeader className="cui-bg-silver-300 cui-text-fg-moderate">This is the SubHeader</Card.SubHeader>
<div className="cui-h-20 cui-flex cui-items-center">This is the body</div>
<Card.PreFooter className="cui-bg-silver-300 cui-text-fg-moderate">This is the PreFooter</Card.PreFooter>
<Card.Footer className="cui-bg-silver-400 cui-text-fg-moderate">This is the Footer</Card.Footer>`;

export const CustomTemplate = (props) => (
<Card {...props}>
    <Card.Header className="cui-py-md">This is a custom Card header</Card.Header>
    <Card.SubHeader className="cui-bg-silver-300 cui-text-fg-moderate">This is the SubHeader</Card.SubHeader>
    <div className="cui-h-20 cui-flex cui-items-center">This is the body</div>
    <Card.PreFooter className="cui-bg-silver-300 cui-text-fg-moderate">This is the PreFooter</Card.PreFooter>
    <Card.Footer className="cui-bg-silver-400 cui-text-fg-moderate">This is the Footer</Card.Footer>
</Card>
);

// Both Storybook and the CUI doc site use these stories as component examples.
export const stories = {
    "Default": {
    ...defaultItems,
    },
    "Default w/ Collapsible": {
        ...defaultItems,
        collapsible: true,
    },
    "Default w/ Collapsible Full Title": {
        ...defaultItems,
        collapsible: true,
        actionText: "See All",
        description: "A card description"
    },
    "Default w/ Custom BG": {
        ...defaultItems,
        collapsible: true,
        headerBg: "bg-primary-minimal"
    },
    "No Header w/ forceHeaderVisibility": {
        ...defaultItems,
        title: undefined,
        forceHeaderVisibility: true
    },
    "Custom Header": {
        ...defaultItems,
        title: null
    },
    "Custom Header w/ Collapsible": {
        ...defaultItems,
        title: null,
        collapsible: true
    },
    "With Initial Height (Expanded)": {
        ...defaultItems,
        title: null,
        collapsible: true,
        initialHeight: 50
    }
};
