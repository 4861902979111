import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Text",
  "sortOrder": 20.3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Text`}</h1>
    <p>{`The text components are a fundamental building block of Caesars UI. They are used to display various types of text content throughout the website or application, including headlines, body copy, labels, and captions. The components are designed to be flexible, allowing it to adapt to different contexts and use cases.`}</p>
    <h4>{`Released`}</h4>
    <ul>
      <li parentName="ul">{`Our `}<a parentName="li" {...{
          "href": "display"
        }}>{`display styles`}</a>{` are for marketing page layouts where larger fonts are needed, live scores, Branded Specials and page titles.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "heading"
        }}>{`heading styles`}</a>{` help organize and structure the content, as well as to highlight important information and create a hierarchy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "body"
        }}>{`Body styles`}</a>{` also known as paragraph styles, can be used to define the appearance of the main body of text within the app.`}</li>
    </ul>
    <h4>{`Installation`}</h4>
    <p>{`For iOS Native developers, we extend UIKit & SwiftUI to easily be able to reference the text components.`}</p>
    <p>{`For Web/Cordova based devs, you have two ways to apply the text component: `}</p>
    <ul>
      <li parentName="ul">{`Use Typography components `}<inlineCode parentName="li">{`Display`}</inlineCode>{`, `}<inlineCode parentName="li">{`Heading`}</inlineCode>{`, and `}<inlineCode parentName="li">{`Body`}</inlineCode></li>
      <li parentName="ul">{`Use Typography CSS Classes. These are tied to each theme, and each theme might have different typography definitions for the classes. An example of a CSS class would be `}<inlineCode parentName="li">{`.display-3xl-bold`}</inlineCode>{`. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      