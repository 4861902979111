import React, { useCallback, useRef, useEffect } from "react";
import { Grid } from "../../../Grid/Grid";
import { MarketProps } from "./types";
import { MarketButton } from "../../../MarketButton/MarketButton";
import { MappedSelection } from "./components/MappedSelection";
import { Label } from "./components/Label";
import { VersusDivider } from "./components/VersusDivider";
import { spanMap } from "./js/spanMap";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import { createMetaData } from "./js/createMetaData";
import { useSize } from "../../../../shared/hooks/useSize";
import { checkHasContainerQueries } from "components/src/shared/utils/supports";
import fastdom from "fastdom";

const defaultHandler = () => {};

const hasContainerQueries = checkHasContainerQueries();

export const Market = ({
  selections,
  competitor,
  competitors,
  type = "row",
  name,
  marketHeader,
  buttonOnly = false,
  displayOrder,
  groupBy,
  oddsFormat = "american",
  dataFilterGroup = "all",
  canSelectMarkets = true,
  selectedSelectionIds,
  marketTemplateTitle,
  onSelectionClick = defaultHandler,
  updateMarket = defaultHandler,
  useSingleSelection = false,
  showVersusDivider = true,
  id: marketId,
  sport,
  hasScore,
  mode,
  isSelectedBuilder,
  priceDisplayFormatter,
  ...rest
}: MarketProps) => {
  const randIdString = useRef(`cui__market-${randomIdString()}`);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const hasCompetitor = !!competitor?.displayName || !!competitor?.team;
  const hasName = !!name;
  const hasMultipleCompetitors =
    Array.isArray(competitors) && competitors.length === 2;

  const handleSingleSelectionClick = useCallback(
    (e, id) => {
      if (
        canSelectMarkets ||
        (selectedSelectionIds && selectedSelectionIds.includes(selections[0].id))
      ) {
        onSelectionClick({ e, id, selection: selections[0], updateMarket });
      }
    },
    [selections[0], updateMarket, canSelectMarkets]
  );

  const marketButtonData = {
    type,
    onSelectionClick,
    oddsFormat,
    updateMarket,
    buttonOnly,
    sport,
    name,
    canSelectMarkets,
    selectedSelectionIds,
    dataFilterGroup,
    marketHeader,
    marketTemplateTitle,
    competitor,
    competitors,
    isSelectedBuilder,
    priceDisplayFormatter
  };

  const { width } = useSize(wrapperRef.current, 200, sport !== "tennis") || { width: null };

  useEffect(() => {
    if (!hasContainerQueries) {
      fastdom.mutate(() => {
        if (wrapperRef.current && wrapperRef.current.offsetParent !== null && wrapperRef.current.offsetParent instanceof HTMLElement) {
          wrapperRef.current.offsetParent.style.setProperty(
            "--cui-market-template-competitor-width",
            `${wrapperRef.current?.clientWidth || 120}px`
          );
        }
      });
    }
  }, [width]);


  // useEffect(() => {
  //   if (wrapperRef.current && wrapperRef.current.offsetParent !== null && wrapperRef.current.offsetParent instanceof HTMLElement) {
  //     wrapperRef.current.offsetParent.style.setProperty(
  //       "--cui-market-template-competitor-width",
  //       `${wrapperRef.current?.clientWidth || 120}px`
  //     );
  //   }
  // }, [width]);

  const metadata = createMetaData({
    competitor,
    competitors,
    marketHeader,
    sport,
    name,
    i: 0,
    marketTemplateTitle,
  });

  if (type === "row") {
    return (
      <Grid
        className="cui__market-row"
        data-filter-group={!useSingleSelection ? dataFilterGroup : null}
        id={marketId || randIdString.current}
        {...rest}
        flush
      >
        {hasCompetitor || hasMultipleCompetitors || hasName ? (
          <Grid.Cell
            colSpan={spanMap[selections?.length]}
            className="cui-flex cui-items-center"
            ref={wrapperRef}
          >
            {hasMultipleCompetitors ? (
              <div className="cui-w-full cui-flex cui-flex-col cui__market-competitor-wrapper">
                <Label
                  competitor={competitors[0]}
                  hasMultipleCompetitors={hasMultipleCompetitors}
                  name={name}
                  buttonOnly={buttonOnly}
                  type={type}
                  sport={sport}
                  mode={mode}
                />
                {showVersusDivider ? <VersusDivider hasScore={hasScore} /> : null}
                <Label
                  competitor={competitors[1]}
                  hasMultipleCompetitors={hasMultipleCompetitors}
                  name={name}
                  buttonOnly={buttonOnly}
                  type={type}
                  sport={sport}
                  mode={mode}
                />
              </div>
            ) : (
              <Label
                competitor={competitor}
                hasMultipleCompetitors={hasMultipleCompetitors}
                name={name}
                buttonOnly={buttonOnly}
                type={type}
                sport={sport}
                mode={mode}
              />
            )}
          </Grid.Cell>
        ) : null}
        {selections?.map((selection, i) => (
          <MappedSelection
            key={`${selection?.id}-${i}`}
            index={i}
            selection={selection}
            marketButtonData={marketButtonData}
          />
        ))}
      </Grid>
    );
  }

  return (
    <div
      className="cui__market-col cui-flex cui-flex-col cui-gap-2xs cui-justify-end"
      data-filter-group-set={`cui__market-col-${marketId}`}
    >
      {hasCompetitor || hasName ? (
        <Grid.Cell className="cui-flex cui-items-center cui-justify-center">
          <Label
            competitor={competitor}
            name={name}
            buttonOnly={buttonOnly}
            type={type}
            hideScore={true}
            sport={sport}
          />
        </Grid.Cell>
      ) : null}
      {useSingleSelection ? (
        <MarketButton
          {...selections[0]}
          metadata={metadata}
          onClick={handleSingleSelectionClick}
          animation={selections[0]?.animation ?? "reduced"}
          fluid={true}
          isDisabled={
            selections[0]?.isDisabled ||
            (!canSelectMarkets && selectedSelectionIds && !selectedSelectionIds.includes(selections[0]?.id))
          }
        />
      ) : (
        selections?.map((selection, i) => (
          <MappedSelection
            key={`${selection?.id}-${i}`}
            index={i}
            selection={selection}
            marketButtonData={marketButtonData}
          />
        ))
      )}
    </div>
  );
};
