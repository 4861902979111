import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Legs",
  code: "Legs"
};
export const _frontmatter = {
  "menuLabel": "Legs",
  "sortOrder": 12.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Legs`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-legs--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Legs } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The Legs component can be used for any bulleted list where you'd like connected lines that show context. Each individual leg can have it's own custom bullet, which can be an `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` or
`}<inlineCode parentName="p">{`TeamLogo`}</inlineCode>{`, as well as `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` or a premade bullet style we've provided.`}</p>
    <p>{`There's also an options object that provides the capability to hide the top or bottom poles, or show the bullet/icon only without rendering the text.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      