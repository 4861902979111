import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Forms",
  "sortOrder": 6.1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Forms`}</h1>
    <p>{`Forms encompass a collection of standardized components and guidelines for collecting and managing user input efficiently and consistently. This category includes various elements such as text fields, checkboxes, radio buttons, dropdowns, and others, each designed to facilitate clear, user-friendly interactions. `}</p>
    <p>{`Effective form design ensures that data entry is intuitive, validation errors are communicated clearly, and the overall user experience is smooth and error-free. By adhering to a design system, forms maintain visual consistency, accessibility, and usability across an application, contributing to a cohesive and professional user interface.`}</p>
    <h2>{`Released`}</h2>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "form-input"
        }}>{`input box`}</a>{` is used as an atom in our form components.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "search-input"
        }}>{`Search input`}</a>{` allows users to search a page or site.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../slider"
        }}>{`Slider`}</a>{` allows users to select a value within a specified range by dragging a handle along a track.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "text-field"
        }}>{`Text field`}</a>{` lets users enter and edit text.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../toggle"
        }}>{`Toggle`}</a>{` change the state of a single item.`}</li>
    </ul>
    <h2>{`Future releases`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Dropdown `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Checkbox and radio, with groups`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Combo box`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Password`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Text area`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Date picker`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Time picker`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Credit card input`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Alerts and validation`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      