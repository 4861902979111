import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Box Score",
  "sortOrder": 2.2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Box Score`}</h1>
    <p>{`The Box Score component highlights a match between two teams.`}</p>
    <h2>{`Released`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "pregame"
        }}>{`Pre-Game`}</a>{` shows a pre-game matchup between two teams.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "live"
        }}>{`Live`}</a>{` shows a live matchup between two teams.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      