import { useEffect, useState, useMemo, useRef } from "react";
import { logos } from "tokens/build/icons/dist";
import camelCase from "lodash-es/camelCase";
import { CollegeBasketball } from "./components/CollegeBasketball";
import { CollegeFootball } from "./components/CollegeFootball";
import { CollegeBaseball } from "./components/CollegeBaseball";
import { CollegeHockey } from "./components/CollegeHockey";
import { Soccer, SoccerJerseyType } from "./components/Soccer";
import {
  TeamLogoProps,
  TeamLogoIconWrapperProps,
  TeamLogoModuleType,
} from "./types";
import { useTeamColors } from "../../shared/hooks/useTeamColors";
import { sizeStyles } from "../../shared/styles/sizeStyles";
import styles from "./TeamLogo.module.scss";
import startCase from "lodash-es/startCase";

const IconWrapper = ({
  children,
  size,
  className = "",
  style,
  rest,
}: TeamLogoIconWrapperProps) => {
  const { sizeClasses } = sizeStyles(size);
  return (
    <i className={`${sizeClasses} ${className} cui__color_icon`} style={style} {...rest}>
      {children}
    </i>
  );
};

function getLogoName(abbr: any, mode: string) {
  return `logo_${abbr.toLowerCase()}_${mode === "dark" ? "onDark" : "onLight"}`;
}

const customLogos = ["college", "soccer"];

export const TeamLogo = ({
  name = "",
  size = "md",
  sport = "basketball",
  mode = "light",
  soccerType = "stripeVertical",
  accessibilityLabel = "",
  ...rest
}: TeamLogoProps) => {
  const [LoadedIcon, setIcon] = useState<TeamLogoModuleType>();
  const [isLoading, setIsLoading] = useState(true);
  const { sizeMap, sizeStyle } = sizeStyles(size);
  const { colors, league, abbreviation: abbr } = useTeamColors(name);
  const firstRenderRef = useRef(true);

  const [primary = "", secondary = "", jerseyType] = colors || [];

  const _name =
    league && !customLogos.includes(league) && abbr
      ? camelCase(getLogoName(abbr, mode))
      : null;

  const isCollege = league === "college";
  const isSoccer = league === "soccer" && sport === "soccer";
  
  useEffect(() => {
    if(firstRenderRef.current) firstRenderRef.current = false;
    if (!isLoading) setIsLoading(true);
    (async () => {
      try {
        if (_name && _name in logos) {
          let logo = await logos[_name]();
          setIcon(() => {
            setIsLoading(false);
            return logo
          });
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error("Could't retrieve logo: ", e);
      }
    })();
  }, [name, mode]);

  const width =
    typeof size === "number"
      ? sizeStyle.width?.replace("px", "")
      : sizeMap.width;
  const height =
    typeof size === "number"
      ? sizeStyle.height?.replace("px", "")
      : sizeMap.height;

  const loadingStyles = useMemo(() => {
    return isLoading ? {} : { minWidth: width, minHeight: height }
  },[isLoading]);


  if (isSoccer)
    return (
      <IconWrapper size={size} rest={rest} className={styles.cuiSoccerOutline} style={loadingStyles}>
        <Soccer
          type={jerseyType as SoccerJerseyType}
          primaryColor={primary}
          secondaryColor={secondary}
          width={sizeMap.width}
          height={sizeMap.height}
          accessibilityLabel={`${startCase(name)} logo`}
        />
      </IconWrapper>
    );
  // ncaaf: football
  if (isCollege || league === "wnba") {
    switch (true) {
      case ["basketball", "ncaab", "ncaawb", "collegeBasketball", "college_basketball"].includes(sport):
        return (
          <IconWrapper size={size} rest={rest} style={loadingStyles}>
            <CollegeBasketball
              fill={primary || "var(--gray-600)"}
              secondaryfill={secondary || "#FEFEFE"}
              width={sizeMap.width}
              height={sizeMap.height}
              accessibilityLabel={`${startCase(name)} logo`}
            />
          </IconWrapper>
        );
      case ["football", "ncaaf", "collegeFootball", "college_football"].includes(sport): 
          return (<IconWrapper size={size} rest={rest} style={loadingStyles}>
            <CollegeFootball
              fill={primary || "var(--gray-600)"}
              secondaryfill={secondary || "#FEFEFE"}
              width={sizeMap.width}
              height={sizeMap.height}
              accessibilityLabel={`${startCase(name)} logo`}
            />
          </IconWrapper>)
      case ["baseball", "ncaabaseball", "dibaseball", "di baseball", "collegeBaseball", "college_baseball"].includes(sport): 
      return (<IconWrapper size={size} rest={rest} style={loadingStyles}>
        <CollegeBaseball
          fill={primary || "var(--gray-600)"}
          secondaryfill={secondary || "#FEFEFE"}
          width={sizeMap.width}
          height={sizeMap.height}
          accessibilityLabel={`${startCase(name)} logo`}
        />
      </IconWrapper>)
      case ["hockey", "ncaah", "collegeHockey", "college_hockey"].includes(sport): 
      return (<IconWrapper size={size} rest={rest} style={loadingStyles}>
        <CollegeHockey
          fill={primary || "var(--gray-600)"}
          secondaryfill={secondary || "#FEFEFE"}
          width={sizeMap.width}
          height={sizeMap.height}
          accessibilityLabel={`${startCase(name)} logo`}
        />
      </IconWrapper>)      
      default:
        return (
          <IconWrapper size={size} rest={rest} style={loadingStyles}>
            <CollegeBasketball
              fill={primary || "var(--gray-600)"}
              secondaryfill={secondary || "#FEFEFE"}
              width={sizeMap.width}
              height={sizeMap.height}
              accessibilityLabel={`${startCase(name)} logo`}
            />
          </IconWrapper>
        );
    }
  }

  if (!LoadedIcon && !firstRenderRef.current && !isLoading) return null;
  return (
    <IconWrapper size={size} rest={rest} style={loadingStyles}>
     {LoadedIcon ? <LoadedIcon.default
        width={width}
        height={height}
        accessibilityLabel={`${startCase(name)} logo`}
      /> : <></>}
    </IconWrapper>
  );
};

TeamLogo.displayName = "TeamLogo";
