import React, {
  Children,
  useRef,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
  forwardRef
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
  Keyboard,
  Mousewheel,
} from "swiper/modules";

import { CarouselProps } from "./types";
import { Button } from "../Button/Button";
import { SkipLink } from "../SkipLink/SkipLink";
import { GradientOverlay } from "./components/GradientOverlay";

import cx from "components/src/utils/cx";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import { useConfig } from "./js/carousel.config";

// CSS
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import styles from "./css/Carousel.module.scss";

const skipLinkDefault = { anchorTag: "", label: "Carousel" };
const breakpointValues = {
  mobile: "280",
  tablet: "768",
};

// const htmlElementTypesArray = ["div", "span", "button", "h1", "h2", "h3", "h4", "h5", "h6", "p", "section", "article", "li"];


export const Carousel = ({
  overlay = true,
  showPagination = true,
  showControls = "hover",
  controlSize = "lg",
  controlButtonType = "tertiary",
  skipLink = skipLinkDefault,
  showFade = true,
  showFadeLeft = true,
  showFadeRight = true,
  slideTo = 0,
  itemsDescription = "",
  itemSpacing = "4",
  centerSlides,
  initialIndex = 0,
  slideWrapperClasses,
  cssMode = false,
  tabbable = true,
  id,
  role = "group",
  forcePagination,
  onItemChange,
  handleItemImpression,
  children,
  ...rest
}: CarouselProps) => {
  const randStr = useRef<string>(randomIdString());
  const generatedId = `carousel-${randStr.current}`;
  const navBtns = useMemo(() => ({ nextElId: `cui__carousel-next-button-${randStr.current}`, prevElId: `cui__carousel-prev-button-${randStr.current}`}), []);
  const paginationClass = `cui__carousel-pagination-${randStr.current}`;
  const carouselWrapper = useRef<HTMLDivElement | null>(null);

  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const { config, breakpoint, isEnd, isBeginning, isLocked, swiperInstance } = useConfig({ navBtns, paginationClass, cssMode, showPagination, forcePagination, itemSpacing, centerSlides, randStr, onItemChange, handleItemImpression })
  
  const _showFade = {
    showFade, 
    showFadeLeft, 
    showFadeRight
  };

  useLayoutEffect(() => {
    if (swiperInstance.current && window !== undefined) {
      let carousel = swiperInstance.current;
      if (typeof slideTo === "number" && carousel) {
        carousel.slideTo(slideTo, 200);
      }
    }
  }, [showPagination, slideTo]);

  useLayoutEffect(() => {
    if (swiperInstance.current) {
      let carousel = swiperInstance.current;
      if (hasFocus) {
        carousel.keyboard.enable();
      } else {
        carousel.keyboard.disable();
      }
    }
  }, [showPagination, hasFocus]);

  const focusHandler = useCallback((e) => {
    setHasFocus(true);
  }, []);
  const blurHandler = useCallback((e) => {
    setHasFocus(false);
  }, []);

  const escapeKeyHandler = useCallback(
    (e) => {
      if (e.key === "Escape") {
        const el = carouselWrapper.current?.firstChild as HTMLElement;
        el.focus();
      }
    },
    [carouselWrapper.current]
  );
  const slides = useMemo(() => {
    return Children.map(children, (child, i) => {
      return (
        <SwiperSlide tabIndex={-1} virtualIndex={i} onKeyUp={escapeKeyHandler} role="group">
          {child}
        </SwiperSlide>
      )
    });
  }, [children]);
  const hasConfiguration = !!Object.entries(config).length;
  return (
    <CarouselWrapper
      navigation={navBtns}
      overlay={overlay}
      breakpoint={breakpoint}
      showPagination={showPagination}
      showControls={showControls}
      controlSize={controlSize}
      controlButtonType={controlButtonType}
      isEnd={isEnd}
      isBeginning={isBeginning}
      skipLink={skipLink}
      onFocus={focusHandler}
      onBlur={blurHandler}
      itemsDescription={itemsDescription}
      isLocked={isLocked}
      tabbable={tabbable}
      forcePagination={forcePagination}
      slideWrapperClasses={slideWrapperClasses}
      paginationClass={paginationClass}
      role={role}
      ref={carouselWrapper}
      id={id ? id : generatedId}
      {...rest}
    >
      {hasConfiguration ? <Swiper
        {...config}
        className={
          `cui__carousel swiper-slide cui__carousel_${randStr.current} focus:cui-outline-none`
        }
      >
        {slides}
        {showFade ? (
          <>
            <GradientOverlay
              slot="container-start"
              className={cx(
                {
                  "cui-opacity-0": isBeginning || !_showFade.showFadeLeft,
                  "cui-opacity-100": !isBeginning && _showFade.showFadeLeft,
                },
                "cui-left-0"
              )}
            />
            <GradientOverlay
              slot="container-end"
              className={cx(
                {
                  "cui-opacity-0": isEnd || !_showFade.showFadeRight,
                  "cui-opacity-100": !isEnd && _showFade.showFadeRight,
                },
                "cui-right-0"
              )}
              flip
            />
          </>
        ) : null}
      </Swiper> : null}
    </CarouselWrapper>
  );
};

export const CarouselWrapper = forwardRef(
  (
    {
      navigation: { nextElId, prevElId },
      overlay,
      breakpoint,
      showPagination,
      showControls,
      controlSize,
      controlButtonType,
      skipLink,
      isEnd,
      isBeginning,
      itemsDescription = "",
      isLocked,
      onFocus,
      onBlur,
      id,
      role = "list",
      className = "",
      forcePagination,
      paginationClass,
      slideWrapperClasses = "",
      tabbable = true,
      children,
      ...rest
    }: Partial<CarouselProps> & {
      breakpoint: "280" | "768" | undefined;
      isEnd: boolean | undefined;
      isBeginning: boolean | undefined;
      isLocked: boolean;
      onFocus: (e: any) => void;
      onBlur: (e: any) => void;
      className?: string;
      paginationClass?: string;
      navigation: {
        nextElId: string,
        prevElId: string
      };
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const noOverlay = !overlay && breakpoint !== breakpointValues.mobile && !isLocked;
    const hasOverlay = overlay || breakpoint === breakpointValues.mobile;
    const showControlFade = showControls !== "none" && !isLocked && (showControls === "always" || noOverlay);
    const _showPagination = showPagination || forcePagination;
    const navBtnClasses = cx(
      {
        "cui-absolute": hasOverlay,
        "cui-invisible": breakpoint === breakpointValues.mobile,
        "cui-relative": noOverlay,
        "cui-opacity-0 group-hover:cui-opacity-100":
          showControls === "hover" && hasOverlay && !isLocked,
        "cui-opacity-0": showControls === "none" && hasOverlay || isLocked,
      },
      "cui-z-10 cui-transition-opacity cui-duration-200"
    );
    const hidePagination = (!forcePagination && breakpoint === breakpointValues.mobile) || !_showPagination;
    
    return (
      <div
        className={`${styles["cui__carousel"]} cui-w-full cui-relative cui-flex cui-flex-col cui-group focus-visible:cui-outline-1 focus-visible:cui-outline-fg-focus focus-visible:cui-outline-offset-2 ${className}`}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        tabIndex={tabbable ? 0 : -1}
        id={id}
        role="region"
        aria-label={`Carousel ${itemsDescription ? `for ${itemsDescription}` : ""}`}
        {...rest}
      >
        { skipLink ? <SkipLink {...skipLink} /> : null }
        <div
          className={`cui-flex cui-items-center cui-relative`}
        >
          <div
            className={cx(
              {
                "cui-ml-2": hasOverlay,
                "cui-mr-sm": noOverlay && !isLocked,
                [styles['cui__carousel_controls_fadein']]: showControlFade
              },
              `cui-left-0 ${navBtnClasses} cui-transition-all cui-duration-200`
            )}
            tabIndex={-1}
          >
            <Button
              id={prevElId}
              size={controlSize}
              type={controlButtonType}
              leftIcon="ui_chevron-left"
              disabled={isBeginning}
              tabIndex={-1}
              aria-label="Previous Item Button"
            />
          </div>
          <div className={`cui-relative cui-overflow-hidden ${slideWrapperClasses}`} tabIndex={-1} role={role}>
            {children}
          </div>
          <div
            className={cx(
              {
                "cui-mr-2": hasOverlay,
                "cui-ml-sm": noOverlay && !isLocked,
                [styles['cui__carousel_controls_fadein']]: showControlFade
              },
              `cui-right-0 ${navBtnClasses} cui-transition-all cui-duration-200`
            )}
            tabIndex={-1}
          >
            <Button
              id={nextElId}
              size={controlSize}
              type={controlButtonType}
              disabled={isEnd}
              leftIcon="ui_chevron-right"
              tabIndex={-1}
              aria-label="Next Item Button"
            />
          </div>
        </div>
        <div
          className={cx(
            {
              "cui-invisible cui-hidden": hidePagination
                // (!forcePagination && breakpoint === breakpointValues.mobile ) || !_showPagination,
            },
            "cui__carousel-pagination-wrapper cui-flex cui-justify-center cui-mt-xs cui-pl-24"
          )}
          tabIndex={_showPagination ? 0 : -1}
        >
          <div className={`cui__carousel-pagination ${paginationClass}`} tabIndex={_showPagination ? 0 : -1} aria-hidden={_showPagination ? "false" : "true"} />
        </div>
      </div>
    );
  }
);