import { useState, useEffect } from "react";

export const useSharedStorybook = ({ code, parent }) => {
    const [data, setData] = useState({});
  
    useEffect(()=>{
      const fetchData = async () => { 
        if (!Object.keys(data).length) {
            const path = code;
            const parentPath = parent ? `${parent}/` : "";
            try {
              const data = await import(`../../../../packages/components/src/components/${parentPath}${path}/storybook-shared`);
              setData(data);
            } catch (e) {
              console.log(e);
            }
        }
        // console.log(data, component);
      }
      fetchData();
    },[]);

    return data;
}