import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "sortOrder": 2.1,
  "menuLabel": "Quick Start (Web)"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Quick Start (Web)`}</h1>
    <p className="p-large">The following info will help you get Caesars UI setup in your project.</p>
    <h2>{`Node Package `}<inlineCode parentName="h2">{`@caesars-digital/caesars-ui`}</inlineCode></h2>
    <p>{`Caesars UI has an official Node Package hosted in Nexus.   `}</p>
    <p>{`Install it using the following:`}</p>
    <p><inlineCode parentName="p">{`npm install @caesars-digital/caesars-ui`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`Note, please make sure you've scoped the `}<inlineCode parentName="p">{`@caesars-digital`}</inlineCode>{` registry in your `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{` for more help for this see `}<a parentName="p" {...{
          "href": "https://docs.npmjs.com/cli/v8/configuring-npm/npmrc#files"
        }}>{`npm Docs`}</a>{`.  Also, you will need to be logged into your VPN.  `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Getting a registry error? If you're unable to scope the registry via `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{`, try the following command `}<inlineCode parentName="p">{`npm config set @caesars-digital:registry https://example-only-please-use-real-url.registry.dtc/`}</inlineCode></p>
    </blockquote>
    <h2>{`Importing Tokens`}</h2>
    <p>{`In order to use our tokens, you'll have to import them into your project. Wherever your project cares about global CSS, import this file:`}</p>
    <p><inlineCode parentName="p">{`@caesars-digital/caesars-ui/dist/tokens/css/caesars-ui-tokens.css`}</inlineCode></p>
    <p>{`In JS`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import "@caesars-digital/caesars-ui/dist/tokens/css/caesars-ui-tokens.css"; // Some react app entry point file
`}</code></pre>
    <p>{`or via CSS`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import "~/node_modules/@caesars-digital/caesars-ui/dist/tokens/css/caesars-ui-tokens.css"; // Actual path may vary
`}</code></pre>
    <p>{`You will then have access to the core tokens as well as the semantic tokens.   Each set of semantic tokens (themes) lives in this file scoped to a class, such as `}<inlineCode parentName="p">{`.czr-dark`}</inlineCode>{`.  This allows you to set themes for the entire project as well as being able to apply a theme within a specific element and it's children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<body class="czr-dark"> // Switch theme project wide by changing this class
   ...
   // Entire project will have czr-dark classes applied.
   <div class="wh-light">
       // This element overrides the globally set theme and will only have 
       // William Hill light classes applied to this block element and it's children.
   </div>
</body>
`}</code></pre>
    <h2>{`Importing Component CSS`}</h2>
    <p>{`Our component library has it's own CSS output that needs to be loaded as well for it to display correctly.  This should also be imported globally.   The CSS in this file is namespaced and should not interfere with already established classes.  This is file can be imported from: `}</p>
    <p><inlineCode parentName="p">{`@caesars-digital/caesars-ui/dist/css/caesars-ui.css`}</inlineCode></p>
    <h2>{`Using Components`}</h2>
    <p>{`Using a component is as simple as importing it directly from the Caesars UI package: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react";
import { CzrButton } from "@caesars-digital/caesars-ui";

export const Feature = (props) => {
    return (
    <div>
        <CzrButton handleClick={/* Click Handler */}>Deposit</CzrButton>
    </div>
    )
}
`}</code></pre>
    {/* To see a list of all available components, visit the Components page. */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      