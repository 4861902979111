import React from "react";
import { useTeamInfo } from "./useTeamInfo";
const defaultReturn = { league: undefined, colors: [undefined, undefined, undefined], abbreviation: undefined};
export const useTeamColors = (searchTerm: string): TeamColorsReturnType => {
    if (!searchTerm) return defaultReturn;
    const foundTeam = useTeamInfo(searchTerm);
    if (foundTeam) {
        const { color } = foundTeam;
        const isSoccer = foundTeam.league === "soccer";
        const colorArr: (string)[] = [color.primary?.value, color.secondary?.value, isSoccer ? foundTeam.attributes?.jerseyType ?? "" : color.tertiary?.value ?? ""];
        return { league: foundTeam.league, name: foundTeam.name, abbreviation: `${foundTeam.league}-${foundTeam.abbreviation.toLowerCase()}`, colors: colorArr }
    }
  return defaultReturn;
};
export type TeamColorsReturnType = { league?: string, abbreviation?: string, colors: string[] | undefined[], name?: string }