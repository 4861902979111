/**
 * Various Support checks for the browser or otherwise
 */


export const isMobile = typeof navigator !== "undefined" && /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);

/**
 * Check for Container Query Support
 */

export function checkHasContainerQueries() {
  if (typeof window === "undefined") return false;
  return window && window?.CSS && "supports" in window?.CSS
    ? CSS?.supports("container-type", "inline-size")
    : false;
}
