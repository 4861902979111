import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Market Button",
  code: "MarketButton"
};
export const _scope = {};
export const scope = [componentName.code, "TeamLogo", "Icon"];
export const _frontmatter = {
  "menuLabel": "Market Button",
  "sortOrder": 13.2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Market Button`}</h1>
    <ComponentDescription of={componentName.code} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <LivePlayround componentName={componentName} scope={scope} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-marketbutton--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { MarketButton } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="ready">
    The market button is clearly visible to users, with a sufficient contrast ratio to the background color. The market button text is large enough to be easily read, and it is written in a clear and concise manner. The market button's purpose is clear and understandable.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="ready">
    Users can easily interact with the market button, whether through clicking, tapping, or some other form of input. The market button is placed in a logical and easily reachable location on the page. There are hover or focus states that make it easier for users to understand the market button's purpose or interact with it.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="ready">
    The market button's purpose is clear and understandable to users. The market button has a clear and concise label that explains its function. There are additional visual or textual cues that help users understand what will happen when they interact with the market button.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status=" ready">
    The market button can be used with a variety of devices and assistive technologies. The market button is compatible with screen readers and other assistive technologies, and it has proper ARIA attributes to ensure that it is properly identified and labeled for these users. The market button can be used on mobile devices and other devices with smaller screens and touch-based input.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      