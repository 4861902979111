import React, { useEffect, useState, useRef } from "react";
import { icons } from "tokens/build/icons/dist";
import { flagMap } from "tokens/build/js/flag-map";
import camelCase from "lodash-es/camelCase";
import get from "lodash-es/get";
import colors from "tokens/build/js/semantic-colors";
import { sizeStyles } from "../../shared/styles/sizeStyles";
import { animationStyles } from "../../shared/styles/animationStyles";
import { IconProps, IconDefaultType } from "./types";
import { customIcons } from "./custom";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import brandIconsWithModes from "tokens/build/js/brandIconsWithModes";

const exceptionsList = ["iconUiDotsVertical"];
const brandIconsWithModesMap = brandIconsWithModes.reduce((acc, icon) => {
  acc[icon] = { light: `${icon}-onLight`, dark: `${icon}-onDark` };
  return acc;
}, {});
const flagNameIgnoreWordsList = ["of", "the", "and"];

export const Icon = ({
  name = "",
  size = "md",
  color = "cui-fill-bg-default",
  secondaryColor = "",
  animate = null,
  className = "",
  options,
  accessibilityLabel = "",
  mode = "light",
  id,
  ...rest
}: IconProps) => {
  const [LoadedIcon, setIcon] = useState<{ default: any }>();
  const [isLoading, setIsLoading] = useState(true);
  let _name = name && camelCase(name);
  let isFlag = /^flag/.test(_name);
  const isBrand = /^brand/.test(_name);
  const isUi = /^ui/.test(_name);
  const _id = id ?? useRef(`cui__icon-id-${randomIdString()}`).current;
  const firstRenderRef = useRef(true);

  const hasIconInName = /^icon/i.test(_name);
  _name =
    hasIconInName || isFlag || isBrand ? _name : camelCase(`icon_${_name}`);
    
  let resultsArr: string[] = [];

  if (!isFlag && !isBrand && !hasIconInName && !isUi) {
    const nameArr =
      typeof name === "string" &&
      name
        .split(" ")
        .map((n) => n.toLowerCase())
        .filter((n) => !flagNameIgnoreWordsList.includes(n));
    for (let [tagArr, iconName] of flagMap) {
      if (Array.isArray(nameArr)) {
        if (nameArr.length === 1) {
          resultsArr.push(camelCase(`flag_${nameArr[0]}`));
          isFlag = true;
          break;
        } else if (nameArr.every((n) => tagArr.includes(n))) {
          resultsArr.push(iconName);
          isFlag = true;
          break;
        }
      }
    }
  }

  const isColor =
    brandIconsWithModes.includes(name) || /Color/.test(_name) || isFlag;
  const isBrandIconWithMode = isColor && brandIconsWithModes.includes(name);
  if (isBrandIconWithMode)
    _name = camelCase(brandIconsWithModesMap[name][mode]);

  const { sizeStyle, sizeMap } = sizeStyles(size);

  useEffect(() => {
    if (firstRenderRef.current) firstRenderRef.current = false;
    if (_name in customIcons && !!customIcons[_name]) {
      setIcon({ default: customIcons[_name] }); // in object with default key because that's what all the other generated ones do.
      setIsLoading(false);
      return;
    } else if (_name && _name in icons) {
      getIcon(_name, setIcon, setIsLoading);
      return;
    } else if (resultsArr.length === 1) {
      getIcon(resultsArr[0], setIcon, setIsLoading);
    } else {
      setIsLoading(false);
    }
  }, [name, color, secondaryColor, mode]);
  
  const animationClasses = animationStyles(animate);
  const sizeClasses = `cui-relative cui-inline-block cui-p-0 cui-m-0 cui-w-auto cui-h-auto [&>svg]:cui-inline-block cui-box-border !cui-not-italic !cui-text-[0px] !cui-leading-[0px]`;
  const portraitAspectRatio = exceptionsList.includes(_name);
  const width =
    typeof size === "number"
      ? sizeStyle.width?.replace("px", "")
      : sizeMap.width;
  const height =
    typeof size === "number"
      ? sizeStyle.height?.replace("px", "")
      : sizeMap.height;

  const loadingStyles = { minWidth: width, minHeight: height };

  if (!LoadedIcon && !firstRenderRef.current && !isLoading) return null;
  return (
    <i
      className={`cui__icons-${String(_name)} ${
        isColor ? "cui__color_icon" : ""
      } ${sizeClasses} ${animationClasses} ${className}`
        .replace("  ", " ")
        .trim()}
      style={isLoading ? loadingStyles : {}}
      {...rest}
    >
      {LoadedIcon?.default ? (
        <LoadedIcon.default
          fill={isFlag ? "" : get(colors, color, "var(--fg-default)")}
          secondaryfill={get(colors, secondaryColor) || secondaryColor}
          width={portraitAspectRatio ? "100%" : width}
          height={portraitAspectRatio ? height : "100%"}
          id={_id}
          options={options}
          accessibilityLabel={accessibilityLabel}
        />
      ) : null}
    </i>
  );
};

async function getIcon(
  name: string,
  setIcon: React.Dispatch<
    React.SetStateAction<
      | {
          default: (props: {
            fill: string;
            secondaryfill: string;
            width: string;
            height: string;
            accessibilityLabel?: string;
            options?: Record<string, any>;
          }) => JSX.Element;
        }
      | undefined
    >
  >,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    if (typeof icons[name] === "function") {
      let icon = await icons[name]();
      setIcon(icon);
    }
    setIsLoading(false);
  } catch (e) {
    setIsLoading(false);
    if (e instanceof Error) handleErr(e);
  }
}

function handleErr(e: Error) {
  console.error("Could't retrieve icon: ", e);
}
