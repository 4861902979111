import React from "react";
import useComponents from "~/hooks/useAllComponents";
import { Carousel } from "@caesars-digital/caesars-ui";
import cx from "components/src/utils/cx";

export const ComponentImages = ({ component, showScreenshots = true, showHeroImage = true }) => {
    const _component =
    useComponents()
      .allAirtableComponents.edges.map((e) => e.node.data)
      .filter(
        (d) =>
          (d.Type === "Component" || d.Type === "Foundation") &&
          d.Name.toLowerCase() === component.display?.toLowerCase()
      )[0] || null;
    const heroImages = _component?.Hero_Image?.localFiles;
    const screenshots = _component?.Screenshots?.localFiles;
    const { publicURL = "", name = "" } = heroImages && heroImages.length ? heroImages[0] : { publicURL: null, name: null };

    console.log({screenshots, heroImages})
    const screenshotsMapped = screenshots?.length
      ? screenshots.map(({ publicURL, name }) => (
        <img
            src={publicURL}
            alt={name}
            className="h-[180px] border-1 border-solid border-fg-subtle rounded-2px"
        />
        ))
      : null;
    
      if (!showHeroImage && !showScreenshots) return null;

    // Ideal width 1280px
    return (
      <div className={cx({
        "mb-20": publicURL && showHeroImage || screenshotsMapped && showScreenshots
      }, "w-full flex flex-col")}>
        {publicURL && showHeroImage ? (
          <img
            src={publicURL}
            alt={name}
            className="w-full border-1 border-solid border-fg-subtle rounded-2px"
          />
        ) : null}
        {screenshotsMapped && showScreenshots ? (
            <Carousel slideWrapperClasses="bg-bg-layer-two w-full" className="mt-10 w-full">
            {screenshotsMapped}
            </Carousel>
        ) : null}
      </div>
    );
};

export default ComponentImages;