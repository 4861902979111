import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Markets",
  "sortOrder": 7
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Market`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "market"
        }}>{`Market`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "market-fixture"
        }}>{`Market Fixture`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "market-footer"
        }}>{`Market Footer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "market-header"
        }}>{`Market Header`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "market-templates"
        }}>{`Market Templates`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      