export default function buildMenu(data, menu = {}) {
  const edges = "allMdx" in data ? data.allMdx.edges : data;
  // If no edges, peace out.
  if (!edges.length) {
    return menu;
  }
  const edge = edges.shift(); // Take first edge

  // Check if folder and get the slug path as array
  const isFolder = /\/$/.test(edge.node.slug);
  const slugPath = edge.node.slug.split("/").filter((p) => !!p);

  // If there's no slug path, it's the index page  
  if (!slugPath.length) {
    menu["index"] = { ...edge.node, isFolder };
    return buildMenu(edges, menu);
  }

  const firstSlug = slugPath[0];

  // If the slug path is only length 1, it's a folder or top level item.
  if (slugPath.length === 1) {
    menu[firstSlug] = { ...edge.node, isFolder };
    isFolder && (menu[firstSlug].submenu = []);
    return buildMenu(edges, menu);
  }

  const lastSlugPath = slugPath.pop(); // take last slug in slugPath

  const level = resolvePath(menu, slugPath); // find the corresponding level (nested in submenu arrays, so only first level is accessible via obj key)

  // If the level hasn't resolved, push the edge back into the array (might be an ordering issue)
  if (!level) {
    edges.push(edge) 
  } else {
    buildLevel(isFolder, level, edge);
  }


  return buildMenu(edges, menu);
}

// First checks if the path exists as a key in the object (first level), otherwise tries to check for it in the submenu array.
function resolvePath(menu, path) {
  return path.reduce((o, p) => {
    if (o && p in o) {
      return o ? o[p] : null;
    }
    if (o && o.isFolder) {
      const [filteredObj] = o.submenu?.filter(
        (submenuItem) => {
          return !!new RegExp(`${p}/`, "i").test(submenuItem.slug)}
      );
      return filteredObj;
    }
    return undefined
  }, menu);
}

function buildLevel(isFolder, level, edge) {
  if (!level) return;
  if (level.isFolder && !("submenu" in level)) level.submenu = [];
  if ("submenu" in level) {
    level.submenu.push({ ...edge.node, isFolder });
  }
}
