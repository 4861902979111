import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Foundations",
  "sortOrder": 6
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Foundations`}</h1>
    <p className="p-large">Foundations are the elements we use to build our components.</p>
    <h4>{`Released`}</h4>
    <ul>
      <li parentName="ul">{`Our `}<a parentName="li" {...{
          "href": "color-system"
        }}>{`color system`}</a>{` is flexible enough that can be adopted for any theme.`}</li>
      <li parentName="ul">{`Determing `}<a parentName="li" {...{
          "href": "depth"
        }}>{`depth`}</a>{` is easier with our box shadows.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "shape"
        }}>{`Shape`}</a>{` attributes are now tokenized, like border width and radius.`}</li>
      <li parentName="ul">{`Using `}<a parentName="li" {...{
          "href": "spacing"
        }}>{`spacing`}</a>{` tokens will help us make our UI more consistent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "typography"
        }}>{`Typography`}</a>{` attributes are tokenized, allowing us to create display and heading styles in the future.`}</li>
    </ul>
    <h4>{`Upcoming Foundations`}</h4>
    <ul>
      <li parentName="ul">{`Motion`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      